<template>
  <div v-if="session" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget v-if="session">
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>{{ $t('session') }}</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('name')"
            label-for="name"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="session.name" type="text" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('description')"
            label-for="description"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <ckeditor
              :editor="editor"
              v-model="session.description"
              :config="editorConfig"
            ></ckeditor>
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Lerninhalte auswählen</strong>
          </legend>
          <PickList
            :source="pickedContentsFormatted.source"
            :target="pickedContentsFormatted.target"
            :updateCallback="updateSession"
            v-model="pickedContents"
            @change="handlePickListChange"
            dataKey="id"
          >
            <template #sourceheader>
              Verfügbare Inhalte
            </template>
            <template #targetheader>
              Zugeordnete Inhalte
            </template>
            <template #item="slotProps">
              <div class="product-item">
                <div class="product-list-detail">
                  <h5 class="mb-2">{{ slotProps.item.name }}</h5>
                </div>
              </div>
            </template>
          </PickList>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showPreviousButton="buttons.showPreviousButton"
            :showNextButton="buttons.showNextButton"
            :showDeleteButton="buttons.showDeleteButton"
            :model="session"
            modelEditRoute="SessionEditPage"
            modelListRoute="Sessions"
            modelRouteParamName="sessionNumber"
            :nextModelNumber="session.nextSessionNumber"
            :previousModelNumber="session.previousSessionNumber"
            :updateCallback="saveSessionContents"
            :createCallback="createSession"
            :deleteCallback="deleteSession"
            :fetchCallback="fetchSessions"
          />
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import ButtonBar from '@/components/ButtonBar.vue';
import PickList from 'primevue/picklist';
import Vue from 'vue';

export default {
  name: 'SessionEditPage',
  components: {
    Widget,
    Breadcrumbs,
    ckeditor: CKEditor.component,
    ButtonBar,
    PickList,
  },
  props: {
    sessionNumber: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      number: parseInt(this.sessionNumber, 10) || null,
      editor: InlineEditor,
      editorConfig: {},
      pickedContents: [[], []],
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters({
      getSessionByNumber: 'getSessionByNumber',
      allContents: 'getContents',
    }),
    isNew() {
      return this.session && this.session._id === '_new';
    },
    session() {
      return this.getSessionByNumber(this.number);
    },
    sessionContents() {
      return this.session ? this.session.contents : [];
    },
    buttons() {
      return {
        showPreviousButton: this.session && this.session.previousSessionNumber ? true : false,
        showNextButton: this.session && this.session.nextSessionNumber ? true : false,
        showDeleteButton: this.isAdmin && this.session && this.session.number !== '_new',
      };
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: this.$t('session'), route: { name: 'Sessions' } },
        { name: this.session && this.session.number !== '_new' ? this.$t('edit') : this.$t('new') },
      ];
    },
    pickedContentsFormatted() {
      if (!this.allContents.length || !this.session || !this.session.contents) {
        return { source: [], target: [] };
      }

      const source = this.allContents.filter(
        (content) => !this.session.contents.includes(content.id)
      );
      const target = this.session.contents
        .map((id) => this.allContents.find((content) => content.id === id))
        .filter((content) => content);

      return { source, target };
    },
  },
  methods: {
    ...mapActions({
      initSession: 'initSession',
      fetchSessions: 'fetchSessions',
      fetchSessionByNumber: 'fetchSessionByNumber',
      updateSession: 'updateSession',
      createSession: 'createSession',
      deleteSession: 'deleteSession',
      fetchContents: 'fetchContents',
    }),

    handlePickListChange(event) {
      if (!event || !event.value || !Array.isArray(event.value) || event.value.length < 2) {
        console.error('Invalid or missing event data', event);
        return;
      }
      this.pickedContents = event.value;
      this.saveSessionContents();
    },
    saveSessionContents() {
      if (!this.pickedContents || this.pickedContents.length < 2) {
        console.error('pickedContents does not have the expected structure:', this.pickedContents);
        return;
      }
      const contentIds = this.pickedContents[1].map((item) => item.id);
      this.$set(this.session, 'contents', contentIds);
      this.updateSession(this.session);
    },
    async setup() {
      this.number = this.sessionNumber ? parseInt(this.sessionNumber, 10) : '_new';
      if (this.number && this.number !== '_new') {
        await this.fetchSessionByNumber(this.number);
        await this.fetchContents();
        this.initializePickListData();
      } else {
        this.initSession();
      }
    },

    initializePickListData() {
      if (!this.session) {
        console.warn('Session not found.');
        return;
      }

      const sessionContentsIds = this.session.contents.map((item) => item.content);

      this.pickedContents = [
        this.allContents.filter((content) => !sessionContentsIds.includes(content.id)),
        this.allContents.filter((content) => sessionContentsIds.includes(content.id)),
      ];
    },
  },
  watch: {
    pickedContents(newVal, oldVal) {},
    '$route.params.sessionNumber': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.number = parseInt(newVal, 10);
          this.setup();
        }
      },
    },
  },
  async mounted() {
    this.setup();
  },
  async beforeRouteUpdate(to, from, next) {
    const newNumber = parseInt(to.params.sessionNumber, 10);
    if (!isNaN(newNumber) && newNumber !== this.number) {
      this.number = newNumber;
      await this.setup();
    }
    next();
  },
};
</script>

<style scoped lang="scss"></style>
